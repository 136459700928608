// Section3.js
import React, { useState } from "react";
import { FormControlLabel, Checkbox, Grid, TextField } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Summary = ({ summary, setCurrentIndex, additionalDiscount }) => {
  const handleNavigate = (index) => {

    setCurrentIndex(index)
  }

  const indexOffset = (index) => {
    let curIndex = index
    if (summary.wantVideo) curIndex += 1
    if (summary.hasLocationAndDate === 'locationAndDate') curIndex += 1
    return curIndex
  }

  if (!summary) return null;
  return (
    <div className={"section " + summary.currentIndex} >
      <CardTitles title="This is your roadmap!" />
      <Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center" className="summaryGridContainer">
        <Grid container className="summary-container gdpr">
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(0)}>
            <div className="summary-item gdpr"><strong>Name</strong>  {summary.name}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(0)}>
            <div className="summary-item gdpr"><strong>Phone</strong>  {summary.phone}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(0)}>
            <div className="summary-item gdpr"><strong>Email</strong>  {summary.email}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(0)}>
            <div className="summary-item gdpr"><strong>Instagram</strong>  {summary.instagram}</div>
          </Grid>
        </Grid>
        <Grid container className="summary-container order">
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(1)}>
            <div className="summary-item"><strong>Photos</strong>  {summary.numPhotos}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(2)}>
            <div className="summary-item"><strong>Video</strong>  {summary.wantVideo ? 'Yeah boy!' : 'Nah, My car looks good stationary'}</div>
          </Grid>
          {summary.wantVideo && <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(3)}>
            <div className="summary-item"><strong>Video Style</strong>  {summary.videoType === 'simple' ? 'A simple reel works for me' : 'Thats gonna be a good cinematic story!'}</div>
          </Grid>}
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={summary.hasLocationAndDate === 'locationAndDate' ? (summary.wantVideo ? () => handleNavigate(4) : () => handleNavigate(3)) : (summary.wantVideo ? () => handleNavigate(4) : () => handleNavigate(3))}>
            <div className="summary-item"><strong>Location</strong>
              {summary.hasLocationAndDate === 'locationAndDate' ? summary.location + ' / ' + summary.date : "We'll discuss location later"}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(indexOffset(4))}>
            <div className="summary-item"><strong>Poster</strong>
              {summary.wantPoster ? 'Oh yeah, new Instagram content' : "Just the photos" + (summary.wantVideo ? " and video" : '') + " are enough"}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(indexOffset(5))}>
            <div className="summary-item"><strong>On spot memory</strong>
              {summary.wantCandid ? 'I love printables!' : "All good, no need of edit and print!"}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(indexOffset(6))}>
            <div className="summary-item"><strong>Phone Case</strong>
              {summary.wantCase ? 'OMG! New phone case' : "No new protection for my phone!"}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container" onClick={() => handleNavigate(indexOffset(7))}>
            <div className="summary-item"><strong>Handoff</strong>
              {summary.handoff ? 'Yes, Take your time' : "No"}</div>
          </Grid>
          <Grid item xs={12} sm={6} className="summary-item-container total">
            <div className="total-sum"><strong>Total</strong> <br />
              {summary.totalSum} Euro / {summary.totalSumRon} RON {additionalDiscount > 0 && <strong><br />Additional Discount: {additionalDiscount} Euro</strong>}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Summary;
