import React, { useState } from "react";
import { FormControlLabel, RadioGroup, Radio, FormGroup, FormControl, Grid } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section4_5 = ({ setHasLocationAndDate, location }) => {
  const handleOptionChange = (e) => {
    setHasLocationAndDate(e.target.value);
  };

  return (
    <div className="section">
      <CardTitles title="It's a date!" subtitle={<div>We have to admit, the location and the date are important, too.</div>} />

      <Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center">

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="hasLocationAndDate" name="hasLocationAndDate" onChange={handleOptionChange} defaultValue={location}>
              <FormControlLabel className="radio-button" value="locationAndDate" control={<Radio />} label="I have the date and location" />
              <FormControlLabel className="radio-button" value="locationScouting" control={<Radio />} label="I need location scouting" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <div className="subnotes">
        <small>If you have the location it will make my life way easier, but, there is always a but... I cannot guarantee the quality of the shots to an unknown location. Location scouting implies me searching for a good location to emphasize your car. It can take a while... </small>
      </div>
    </div>
  );
}

export default Section4_5;
