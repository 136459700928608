// Section2.js
import React from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section2 = ({ numPhotos, handleNumPhotosChange, discount }) => {
    return (
        <div className="section" >
            <CardTitles title="Number of photos." subtitle={<div>How many <strong>bangers</strong> would you like to get?</div>} />
            <Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <FormControl className="select-control">
                        <Select className="select-input"
                            name="numOfPhotos"
                            defaultValue={numPhotos}
                            onChange={(e) => handleNumPhotosChange(e.target.value)}
                        >
                            <MenuItem selected value={0}>0</MenuItem>
                            <MenuItem selected value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                        </Select>
                        <div className="discount">
                            Discount <br />
                            <strong>{discount}%</strong>
                        </div>
                    </FormControl>
                </Grid>
            </Grid>
            <div className="subnotes">
                <small>Please note that starting from 15 photos, I do not guarantee the uniqueness of every shot. The shooting implies a lot of photos and I will deliver the best selected amount shots!</small>
            </div>
        </div>
    );
}

export default Section2;
