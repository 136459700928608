// Section3.js
import React, { useState } from "react";
import { FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section7 = ({ setWantCandid }) => {
	const [wantCandidState, setWantCandidState] = useState(false);

	const handleWantCandidChange = (e) => {
		setWantCandidState(e.target.checked);
		setWantCandid(e.target.checked);
	};

	return (
		<div className="section" >
			<CardTitles title="Printed feelings?" subtitle={<div>I would like you not to leave <strong>empty-handed</strong>. Do you want <strong>on spot edit</strong> and a printed postcard? </div>} />
			<Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center">
				<Grid item xs={12} className="centeredGridItem">
					<FormControlLabel
						control={<Checkbox checked={wantCandidState} onChange={handleWantCandidChange} />}
						label="I want a printed photography"
					/>
				</Grid>
			</Grid>
			<div className="subnotes">
				<small>We all agree with that Romanian words "ce-i in mana nu-i minciuna". I can edit one of the photos, on the spot and print it on a 4"x6" postcard format.</small>
			</div>
		</div>
	);
}

export default Section7;
