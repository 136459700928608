import React from "react";
import { FormControl, FormControlLabel, RadioGroup, Radio, Grid } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section4 = ({ videoType, setVideoType }) => {

  const handleVideoTypeChange = (e) => {
    setVideoType(e.target.value);
  };

  return (
    <div className="section">
      <CardTitles title="Whoaa, we talk about video now" subtitle={<div>Do you think some short snaps will do the job? Or does your car have, <br /><strong>a story?</strong></div>} />

      <Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center">

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="videoType" name="videoType" onChange={handleVideoTypeChange}>
              <FormControlLabel checked={videoType === 'simple'} className="radio-button" value="simple" control={<Radio />} label="Simple Reel" />
              <FormControlLabel checked={videoType === 'cinematic'} className="radio-button" value="cinematic" control={<Radio />} label="Cinematic Story Video" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <div className="subnotes">
        <small>Simple video edits usually implies a 15/20 sec vertical montage for social media. A cinematic story implies a lot of preplanning, acting, a storyline, sound design, and many more.</small>
      </div>
    </div>
  );
}

export default Section4;
