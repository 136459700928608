// Section3.js
import React, { useState } from "react";
import { FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section3 = ({ setWantVideo }) => {
  const [wantVideo, setWantVideoState] = useState(false);

  const handleWantVideoChange = (e) => {
    setWantVideoState(e.target.checked);
    setWantVideo(e.target.checked);
  };

  return (
    <div className="section" >
      <CardTitles title="Do you want a video, too?" subtitle={<div>If you understand that the <strong>beauty</strong> of your car is on the <strong>"moving"</strong> side.</div>} />
      <Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center">
        <Grid item xs={12} className="centeredGridItem">
          <FormControlLabel
            control={<Checkbox checked={wantVideo} onChange={handleWantVideoChange} />}
            label="Yes"
          />
        </Grid>
      </Grid>
      <div className="subnotes">
        <small>Please note that on the video side, there are chances that your face may appear on the video. You must agree with that!</small>
      </div>
    </div>
  );
}

export default Section3;
