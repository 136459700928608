// App.js
import React, { useEffect } from "react";




const Reset = () => {
	useEffect(() => {
		setTimeout(() => window.close(), 3000)
	}, [])

	return (
		<></>
	);
}

export default Reset;
