// App.js
import React, { useEffect, useReducer, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import logo from './images/logoOrange.png'
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100dvh",
    maxHeight: "100dvh",
    position: "relative",
  },

}));


const App = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div className={classes.root + ' ce-cauti-in-codul-meu'}>

      <header style={{ marginBottom: "20px" }}>
        <img src={logo} alt="Logo" className="logo" />
      </header>

      <div
        className={`horizontalCard  with-drop-shadow`}
      >

        <div className="section-container">
          <h5>Thank you!</h5>
          <small>Please check your email to make sure that everything is allright. <strong>(check the spam folder, too)</strong>.</small>
          <br />
          <br />
          <small><strong>I'll contact you through the instagram, email or Whatsapp</strong></small>
          <br />
          <br />
          <Button onClick={() => navigate('/')} variant="contained" color="success">Start again</Button>
        </div>


        <div className="background">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

    </div>
  );
}

export default App;
