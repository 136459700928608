// SectionDivider.js
import React, { useEffect, useState } from "react";
import 'react-html5video/dist/styles.css';
import expand from '../images/expand.png'

const SectionDivider = ({ images, text, video = false }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [currentImage, setCurrentImage] = useState(images[0])
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!video) changeImage();
    }, 5000); // Change image every 5 seconds (5000 milliseconds)

    return () => clearInterval(interval);
  }, [currentImageIndex]);

  const handleResize = (e) => {
    setWindowSize(e.target.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeImage = () => {
    if (currentImageIndex < (images.length - 1)) {
      setCurrentImageIndex(currentImageIndex + 1)
      setCurrentImage(images[currentImageIndex + 1])
    } else {
      setCurrentImageIndex(0);
      setCurrentImage(images[0])

    }
  }

  if (!currentImage) return null
  if (windowSize < 780 && video) return null

  return (
    <div className="section-divider with-box-shadow">
      {/* <div className="expand">
        <img src={expand} />
      </div> */}
      {video ? <video autoPlay loop muted
        controls={false}
        className='video'
        playsInline={true}>
        <source src={currentImage} type="video/webm" />
      </video> :
        <img src={currentImage} alt="Divider" />
      }
    </div>
  );
}

export default SectionDivider;
