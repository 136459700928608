// App.js
import React, { useEffect, useReducer, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import './homepage.css'
import { useNavigate } from 'react-router-dom';
import ComputerIcon from '@material-ui/icons/Computer';


import logo from './images/logoOrange.png'
import og from './images/og-image.jpg'
import dark1 from './images/cars/dark1.jpeg';
import dark2 from './images/cars/dark2.jpeg';
import dark3 from './images/cars/dark3.jpeg';
import dark4 from './images/cars/dark4.jpeg';
import dark5 from './images/cars/dark5.jpeg';
import dark6 from './images/cars/dark6.jpeg';
import dark7 from './images/cars/dark7.jpeg';
import dark8 from './images/cars/dark8.jpeg';
import mid1 from './images/cars/mid1.jpeg';
import mid2 from './images/cars/mid2.jpeg';
import mid3 from './images/cars/mid3.jpeg';
import mid4 from './images/cars/mid4.jpeg';
import mid5 from './images/cars/mid5.jpeg';
import light1 from './images/cars/light1.jpeg';
import light2 from './images/cars/light2.jpeg';
import light3 from './images/cars/light3.jpeg';
import light4 from './images/cars/light4.jpeg';
import light5 from './images/cars/light5.jpeg';
import light6 from './images/cars/light6.jpeg';
import poster1 from './images/cars/Poster1.jpeg';
import poster2 from './images/cars/Poster2.jpeg';
import poster3 from './images/cars/Poster3.jpeg';
import poster4 from './images/cars/Poster4.jpeg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100dvh",
    maxHeight: "100dvh",
    position: "relative",
  },

}));



const Homepage = () => {
  const [activeButton, setActiveButton] = useState(false)
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveButton(!activeButton);
    }, 3000);

    return () => clearInterval(interval);
  }, [activeButton]);

  return (
    <div className={classes.root + ' ce-cauti-in-codul-meu homepage'}>
      <header className="homepage">
        <img src={logo} alt="Logo" className="logo" />
        <div className="desktop"><ComputerIcon /> Use a desktop device for a better experience!</div>
      </header>
      <div className="button-holder"><button onClick={() => navigate('/now')} className={`${activeButton ? 'active' : ''}`}>
        <span className="text">Let's do it!</span>
        <span className="shimmer"></span>
      </button></div>
      <small className="geoLocationHeadsUp">The location is used locally to determine if you're from Iasi County so we can colaborate further!</small>

      <div className="container">
        <div className="photobanner">
          <img className="first" src={dark3} />
          <img src={dark4} />
          <img src={dark5} />
          <img src={dark6} />
          <img src={dark8} />
        </div>
        <div className="container">
          <div className="photobanner">
            <img className="second" src={mid1} />
            <img src={mid2} />
            <img src={mid3} />
            <img src={mid4} />
            <img src={mid5} />
          </div>
        </div>
        <div className="container">
          <div className="photobanner">
            <img className="first" src={light1} />
            <img src={light3} />
            <img src={light4} />
            <img src={light5} />
            <img src={light6} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
