import { createContext, useEffect, useState } from "react";

// Create a context to manage the script loading state
const CloudinaryScriptContext = createContext();

function CloudinaryUploadWidget({ uwConfig, setPublicId, setCarPhoto }) {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		// Check if the script is already loaded
		if (!loaded) {
			const uwScript = document.getElementById("uw");
			if (!uwScript) {
				// If not loaded, create and load the script
				const script = document.createElement("script");
				script.setAttribute("async", "");
				script.setAttribute("id", "uw");
				script.src = "https://upload-widget.cloudinary.com/global/all.js";
				script.addEventListener("load", () => setLoaded(true));
				document.body.appendChild(script);
			} else {
				// If already loaded, update the state
				setLoaded(true);
			}
		}
	}, [loaded]);


	const initializeCloudinaryWidget = () => {
		if (loaded) {
			var myWidget = window.cloudinary.createUploadWidget(
				uwConfig,
				(error, result) => {
					if (!error && result && result.event === "success") {
						setPublicId(result.info.public_id);
						setCarPhoto(result.info.url);
					}
				}
			);

			document.getElementById("upload_widget").addEventListener(
				"click",
				function () {
					myWidget.open();
				},
				false
			);
		}
	};

	useEffect(() => {
		initializeCloudinaryWidget();
	}, [])

	return (
		<CloudinaryScriptContext.Provider value={{ loaded }}>
			<button
				id="upload_widget"
				className="cloudinary-button"
				onClick={initializeCloudinaryWidget}
			>
				Upload a random picture of your car
			</button>
		</CloudinaryScriptContext.Provider>
	);
}

export default CloudinaryUploadWidget;
export { CloudinaryScriptContext };
