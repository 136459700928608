import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThankYou from './ThankYou'; // Import your ThankYou component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './homepage';
import Enjoy from './enjoy';
import Reset from './reset';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/now" element={<App />} />
        <Route path="/enjoy" element={<Enjoy />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/reset" element={<Reset />} />
      </Routes>
    </React.StrictMode>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
