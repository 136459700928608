// Section3.js
import React, { useState } from "react";
import { FormControlLabel, Checkbox, Grid, TextField } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section8 = ({ setWantCase, setPhoneModel }) => {
	const [wantCaseState, setWantCaseState] = useState(false);
	const [phoneModelState, setPhoneModelState] = useState('');

	const handleWantCaseChange = (e) => {
		setWantCaseState(e.target.checked);
		setWantCase(e.target.checked);
		if (!e.target.checked) {
			setPhoneModel(null)
			setPhoneModelState(null)
		}
	};
	const handleSetPhoneModel = async (e) => {
		await setPhoneModelState(e.target.value)
		setPhoneModel(e.target.value);
	};

	return (
		<div className="section" >
			<CardTitles title="In CASE you wondered" subtitle={<div>Maybe an wallpaper is enough, but your phone may be more <strong>attractive</strong> with your screen facing down!</div>} />
			<Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center">
				<Grid item xs={12} className="centeredGridItem">
					<FormControlLabel
						control={<Checkbox checked={wantCaseState} onChange={handleWantCaseChange} />}
						label="I want a case"
						className="want-case"
					/>
				</Grid>

				{wantCaseState && <Grid item xs={12} sm={6}>
					<TextField
						label="Phone Model"
						value={phoneModelState}
						onChange={handleSetPhoneModel}
						color="secondary"
					/>
				</Grid>}
			</Grid>
			<div className="subnotes">
				<small>If its a YES, please complete with the smartphone model and I will provide a personalized smartphone case with the picture you can choose afterwards.</small>
			</div>
		</div>
	);
}

export default Section8;
