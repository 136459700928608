// Section3.js
import React, { useState } from "react";
import { FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section6 = ({ setWantPoster }) => {
	const [wantPosterState, setWantPosterState] = useState(false);

	const handleWantPosterChange = (e) => {
		setWantPosterState(e.target.checked);
		setWantPoster(e.target.checked);
	};

	return (
		<div className="section" >
			<CardTitles title="Do you want to post 'er?" subtitle={<div>Would you like to <strong>post 'er</strong> a poster? huh, see what I did there?</div>} />
			<Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center">
				<Grid item xs={12} className="centeredGridItem">
					<FormControlLabel
						control={<Checkbox checked={wantPosterState} onChange={handleWantPosterChange} />}
						label="I want an awesome poster"
					/>
				</Grid>
			</Grid>
			<div className="subnotes">
				<small>Please note that on the video side, there are chances that your face may appear on the video. You must agree with that!</small>
			</div>
		</div>
	);
}

export default Section6;
