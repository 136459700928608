// Section3.js
import React, { useState } from "react";
import { FormControlLabel, Checkbox, Grid } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section9 = ({ setHandoff }) => {
	const [handoffState, setHandoffState] = useState(false);

	const handleHandoffChange = (e) => {
		setHandoffState(e.target.checked);
		setHandoff(e.target.checked);
		if (!e.target.checked) {
			setHandoff(false)
			setHandoffState(false)
		}
	};

	return (
		<div className="section" >
			<CardTitles title="Keys HANDOFF?" subtitle={<div>Some good shots take time! You can handoff the car to me for a couple of days to make the most out of it!</div>} />
			<Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center">
				<Grid item xs={12} className="centeredGridItem">
					<FormControlLabel
						control={<Checkbox checked={handoffState} onChange={handleHandoffChange} />}
						label="Handoff the car"
						className="want-case"
					/>
				</Grid>
			</Grid>
			<div className="subnotes">
				<small>With this statement I ensure the integrity of the vehicle while its in my possesion.</small>
			</div>
		</div>
	);
}

export default Section9;
