
import React from "react";
import coin from '../images/coin.png'
import instagramIcon from '../images/instagram.png'
import youtube from '../images/youtube.png'

const CardTitles = ({ title, subtitle }) => {

  return (
    <div className="section-titles">
      <div className="titles">
        <div className="headline">
          <img src={coin} />
          <h4>{title}</h4>
        </div>
        <div className="socials">
          <a href="https://instagram.com/imalinzvinca" target="_blank">
            <img src={instagramIcon} />
          </a>
          <a href="https://www.youtube.com/@AlinZvinca" target="_blank">
            <img src={youtube} />
          </a>
        </div>
      </div>
      {subtitle && <h1>{subtitle}</h1>}
    </div>
  );
}

export default CardTitles;
