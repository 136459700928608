// Section5.js
import React, { useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import CardTitles from "./CardTitles";

const Section5 = ({ setLocation, setDate }) => {
  const [location, setLocationState] = useState("");
  const [date, setDateState] = useState("");

  const handleLocationChange = (e) => {
    setLocationState(e.target.value);
    setLocation(e.target.value);
  };

  const handleDateChange = (e) => {
    setDateState(e.target.value);
    setDate(e.target.value);
  };

  return (
    <div className="section">
      <CardTitles title="Right on the spot!" subtitle={<div>Please tell me when and what you have in mind, so I can take a <strong>look.</strong></div>} />


      <Grid container spacing={3} alignContent="center" alignItems="center" justifyContent="center" className="dateLocation">
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            label="Location"
            value={location}
            onChange={handleLocationChange}

            required
            color="secondary"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            label="Date"
            type="date"
            value={date}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            required
            color="secondary"
          />
        </Grid>
      </Grid>
      <div className="subnotes">
        <small>I'll try to bring any place to life, but some of them are just not gonna work. So, yet again, I'll do my best but not guarantee!</small>
      </div>
    </div>
  );
}

export default Section5;
