// App.js
import React, { useEffect, useReducer, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Cloudinary } from '@cloudinary/url-gen';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';

import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section4_5 from "./components/Section4_5";
import Section5 from "./components/Section5";
import SectionDivider from "./components/SectionDivider";
import Summary from "./components/Summary";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ComputerIcon from '@material-ui/icons/Computer';

import logo from './images/logoOrange.png'
import og from './images/og-image.jpg'
import dark1 from './images/cars/dark1.jpeg';
import dark2 from './images/cars/dark2.jpeg';
import dark3 from './images/cars/dark3.jpeg';
import dark4 from './images/cars/dark4.jpeg';
import dark5 from './images/cars/dark5.jpeg';
import dark6 from './images/cars/dark6.jpeg';
import dark7 from './images/cars/dark7.jpeg';
import dark8 from './images/cars/dark8.jpeg';
import mid1 from './images/cars/mid1.jpeg';
import mid2 from './images/cars/mid2.jpeg';
import mid3 from './images/cars/mid3.jpeg';
import mid4 from './images/cars/mid4.jpeg';
import mid5 from './images/cars/mid5.jpeg';
import light1 from './images/cars/light1.jpeg';
import light2 from './images/cars/light2.jpeg';
import light3 from './images/cars/light3.jpeg';
import light4 from './images/cars/light4.jpeg';
import light5 from './images/cars/light5.jpeg';
import light6 from './images/cars/light6.jpeg';
import poster1 from './images/cars/Poster1.jpeg';
import poster2 from './images/cars/Poster2.jpeg';
import poster3 from './images/cars/Poster3.jpeg';
import poster4 from './images/cars/Poster4.jpeg';

import roller from './images/cars/roller.webm'
import phonecase from './images/zaphonecase.webm'
import postcard from './images/postcard.webm'



import Section6 from "./components/Section6";
import Section7 from "./components/Section7";
import Section8 from "./components/Section8";
import { Scrollbars } from "react-custom-scrollbars";
import CardTitles from "./components/CardTitles";
import Section9 from "./components/Section9";
import { TextField } from "@material-ui/core";
import CloudinaryUploadWidget from "./CloudinaryUploadWidget";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100dvh",
    maxHeight: "100dvh",
    position: "relative",
  },

}));

const prices = {
  fivePhotosPrice: 40,
  simpleVideoPrice: 100,
  cinematicVideoPrice: 200,
  locationScouting: 30,
  candidEdit: 15,
  poster: 10,
  phoneCase: 20,
  handoff: 25,
  additionalDiscount: 40
}

const App = () => {
  const navigate = useNavigate();
  const form = useRef();
  const MAX_FILE_SIZE_MB = 1.9; // Maximum file size in megabytes



  const [currentIndex, setCurrentIndex] = useState(0);
  const [beforeSubmit, setBeforeSubmit] = useState(false)
  const [captcha, setCaptcha] = useState(false)
  const [scrollTop, setScrollTop] = useState(0);
  const [loading, setLoading] = useState(false);

  const [additionalDiscount, setAdditionalDiscount] = useState(false);


  const classes = useStyles();
  const [geoLocation, setGeoLocation] = useState('');
  const [geoLocationError, setGeoLocationError] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [instagram, setInstagram] = useState('');
  const [carDetailsPopup, setCarDetailsPopup] = useState(false);
  const [carDetails, setCarDetails] = useState('');
  const [carPhoto, setCarPhoto] = useState(null);
  const [numPhotos, setNumPhotos] = useState(5);
  const [wantVideo, setWantVideo] = useState(false);
  const [videoType, setVideoType] = useState('simple');
  const [wantPoster, setWantPoster] = useState(false);
  const [wantCandid, setWantCandid] = useState(false);
  const [wantCase, setWantCase] = useState(false);
  const [phoneModel, setPhoneModel] = useState(false);
  const [hasLocationAndDate, setHasLocationAndDate] = useState('locationAndDate');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [handoff, setHandoff] = useState(false);
  const [lastWords, setLastWords] = useState('');
  const [totalSum, setTotalSum] = useState('50');
  const [totalSumRon, setTotalSumRon] = useState('50');


  const [discount, setDiscount] = useState(0);

  const [shouldNext, setShouldNext] = useState(true)
  const [summary, setSummary] = useState({
    name: name,
    phone: phone,
    email: email,
    instagram: instagram,
    carDetails: carDetails,
    carPhoto: carPhoto,
    numPhotos: numPhotos,
    wantVideo: wantVideo,
    videoType: videoType,
    hasLocationAndDate: hasLocationAndDate,
    wantPoster: wantPoster,
    wantCandid: wantCandid,
    wantCase: wantCase,
    phoneModel: phoneModel,
    totalSum: totalSum,
    totalSumRon: totalSumRon,
    location: location,
    handoff: handoff,
    date: date,
    currentIndex: currentIndex,
    lastWords: lastWords
  });
  const [allSections, setAllSections] = useState([
    { id: 1, component: <Section1 setName={setName} setPhone={setPhone} setEmail={setEmail} setInstagram={setInstagram} setCarDetailsPopup={setCarDetailsPopup} carDetails={carDetails} carPhoto={carPhoto} />, condition: true },
    { id: 2, component: <Section2 numPhotos={numPhotos} handleNumPhotosChange={setNumPhotos} discount={discount} />, condition: true },
    { id: 3, component: <Section3 setWantVideo={setWantVideo} />, condition: true },
    { id: 4, component: <Section4 setVideoType={setVideoType} videoType={videoType} />, condition: wantVideo },
    { id: 5, component: <Section4_5 setHasLocationAndDate={setHasLocationAndDate} location={hasLocationAndDate} />, condition: true },
    { id: 6, component: <Section5 setDate={setDate} setLocation={setLocation} />, condition: hasLocationAndDate === 'locationAndDate' },
    { id: 7, component: <Section6 setWantPoster={setWantPoster} />, condition: true },
    { id: 8, component: <Section7 setWantCandid={setWantCandid} />, condition: true },
    { id: 9, component: <Section8 setWantCase={setWantCase} setPhoneModel={setPhoneModel} />, condition: true },
    { id: 10, component: <Section9 setHandoff={setHandoff} />, condition: true },
    { id: 11, component: <Summary summary={summary} setCurrentIndex={setCurrentIndex} additionalDiscount={additionalDiscount} />, condition: true },
  ]);

  const allDividers = [
    [dark7, dark5, dark2, dark8, dark4,],
    [dark3, light1, light2, light3, mid3],
    [roller],
    [mid4, light5, light6, mid5],
    [dark1, dark3, dark6, dark7],
    [mid2, dark7, light3],
    [poster2, poster3, poster1],
    [postcard],
    [phonecase],
    [dark2, light4, mid1, dark6],
    [dark5, light3, mid4, poster1],
  ]

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  function success(pos) {
    var crd = pos.coords;
    setGeoLocation(`Lat : ${crd.latitude} | Long: ${crd.longitude} | Acc: ${crd.accuracy} meters.`)

    if ((crd.latitude <= 47 || crd.latitude >= 48 || crd.longitude <= 27 || crd.longitude >= 28) && !geoLocationError) {
      alert("It seems like you're not completing this form from Iasi, Romania. If it's a mistake or you still want to colaborate, you can still fill the form");
      setGeoLocationError(true);
    }
  }

  function errors(err) {
    console.log(`The location is used locally to determine if you're from Iasi County so we can colaborate further!`);
    setGeoLocationError(true);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    if (!beforeSubmit) setBeforeSubmit(true)

    // emailjs
    //   .sendForm('service_yr07gcm', 'template_lyd2zif', form.current, {
    //     publicKey: 'Qh3V907uTO6eQfxpB',
    //   })
    //   .then(
    //     () => {
    //       console.log('SUCCESS!');
    //     },
    //     (error) => {
    //       console.log('FAILED...', error.text);
    //     },
    //   );
  };

  const submitEmail = async (e) => {
    setLoading(true);
    e.preventDefault();
    await emailjs
      .sendForm('service_yr07gcm', 'template_lyd2zif', form.current, {
        publicKey: 'Qh3V907uTO6eQfxpB',
      })
      .then(
        () => {
          setLoading(false);
          window.location.href = 'https://maper.info/RNmJF2';


        },
        (error) => {
          setLoading(false);
          alert('Submit failed: ' + error.text);
        },
      );
  }

  const scrollEvent = (props) => {
    setScrollTop(props.scrollTop)
  }


  const updateCondition = (id, condition) => {

    setAllSections(
      allSections.map((item) => {
        if (item.id === id) {
          return { ...item, condition };
        } else {
          return item;
        }
      })
    );
  };

  const shouldWeHaveNext = () => {
    let flag = true;
    if (availableSections[currentIndex].id === 1) {
      if (name === '' || email === '' || phone === '' || carDetails === '') {
        flag = false
      }
    }

    if (availableSections[currentIndex].id === 6) {
      if (location === '' || date === '') {
        flag = false
      }
    }

    setShouldNext(flag)
  }

  const updateCart = () => {
    let sum = 0
    sum += calculatePrice(numPhotos)
    if (wantVideo) {
      if (videoType === 'simple') sum += prices['simpleVideoPrice']
      if (videoType === 'cinematic') sum += prices['cinematicVideoPrice']
    }
    if (hasLocationAndDate === 'locationScouting') sum += prices['locationScouting']
    if (wantPoster) sum += prices['poster']
    if (wantCandid) sum += prices['candidEdit']
    if (wantCase) sum += prices['phoneCase']
    if (handoff) sum += prices['handoff']


    if (wantVideo && videoType === 'cinematic' && hasLocationAndDate === 'locationScouting' && wantPoster && wantCandid && wantCase) {
      setAdditionalDiscount(prices['additionalDiscount']);
      sum -= prices['additionalDiscount'];
    } else {
      setAdditionalDiscount(0);
    }

    setTotalSum(sum)

    const amountInEuro = sum; // Your amount in Euro here
    convertToRON(amountInEuro)
      .then((amountInRON) => {
        if (amountInRON !== null) {
          setTotalSumRon(Math.ceil(amountInRON))
        }
      });

    const newSummary = {
      name: name,
      phone: phone,
      email: email,
      instagram: instagram,
      carDetails: carDetails,
      carPhoto: carPhoto,
      numPhotos: numPhotos,
      wantVideo: wantVideo,
      videoType: videoType,
      hasLocationAndDate: hasLocationAndDate,
      wantPoster: wantPoster,
      wantCandid: wantCandid,
      wantCase: wantCase,
      phoneModel: phoneModel,
      totalSum: totalSum,
      totalSumRon: totalSumRon,
      location: location,
      date: date,
      handoff: handoff,
      lastWords: lastWords,
      currentIndex: currentIndex
    }

    setSummary(newSummary)
  }

  const fetchExchangeRate = async () => {
    try {
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/EUR');
      const data = await response.json();
      return data.rates;
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
      return null;
    }
  };

  const convertToRON = async (amountInEuro) => {
    const rates = await fetchExchangeRate();
    if (!rates) {
      console.error('Error fetching exchange rates');
      return null;
    }

    const euroRate = rates.EUR;
    const ronRate = rates.RON;
    if (!euroRate || !ronRate) {
      console.error('Euro or RON rate not found in exchange rates');
      return null;
    }

    return amountInEuro * ronRate;
  };



  useEffect(() => {
    if (!wantVideo) {
      setVideoType('simple')
    }

    if (hasLocationAndDate !== 'locationAndDate') { setLocation(''); setDate(''); }

    updateCart()
    const newSections = allSections.map((section) => {
      if (section.id === 11) {
        return { ...section, component: <Summary summary={summary} setCurrentIndex={setCurrentIndex} additionalDiscount={additionalDiscount} /> };
      }
      return section;
    });
    setAllSections(newSections);
    shouldWeHaveNext()
  }, [numPhotos, wantVideo, videoType, hasLocationAndDate, wantPoster, wantCandid, wantCase, name, email, phone, instagram, date, location, currentIndex, handoff, carDetails, carPhoto, lastWords, additionalDiscount])

  useEffect(() => {
    setScrollTop(0)
  }, [currentIndex])

  useEffect(() => {
    updateCondition(4, wantVideo)
  }, [wantVideo])

  useEffect(() => {
    const newSections = allSections.map((section) => {
      if (section.id === 1) {
        return { ...section, component: <Section1 setName={setName} setPhone={setPhone} setEmail={setEmail} setInstagram={setInstagram} setCarDetailsPopup={setCarDetailsPopup} carDetails={carDetails} carPhoto={carPhoto} /> };
      }
      return section;
    });
    setAllSections(newSections);
  }, [carDetails, carPhoto]);

  useEffect(() => {
    const newSections = allSections.map((section) => {
      if (section.id === 2) {
        return { ...section, component: <Section2 numPhotos={numPhotos} handleNumPhotosChange={setNumPhotos} discount={discount} /> };
      }
      return section;
    });
    setAllSections(newSections);
  }, [discount]);

  useEffect(() => {
    const newSections = allSections.map((section) => {
      if (section.id === 4) {
        return { ...section, component: <Section4 setVideoType={setVideoType} videoType={videoType} /> };
      }
      return section;
    });
    setAllSections(newSections);
  }, [videoType]);

  useEffect(() => {
    const newSections = allSections.map((section) => {
      if (section.id === 5) {
        return { ...section, component: <Section4_5 setHasLocationAndDate={setHasLocationAndDate} location={hasLocationAndDate} update={Math.random()} /> };
      }
      return section;
    });
    setAllSections(newSections);
    updateCondition(6, hasLocationAndDate === 'locationAndDate')
  }, [hasLocationAndDate]);

  const availableSections = allSections.filter((section) => section.condition)

  const handleNext = () => {
    updateCart()
    let nextIndex = currentIndex + 1;
    while (nextIndex < availableSections.length && !availableSections[nextIndex].condition) {
      nextIndex++;
    }
    if (nextIndex < availableSections.length) {
      setCurrentIndex(nextIndex);
    }

  };

  const handlePrev = () => {
    updateCart()
    let prevIndex = currentIndex - 1;
    while (prevIndex >= 0 && !availableSections[prevIndex].condition) {
      prevIndex--;
    }
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex);
    }
  };

  const calculatePrice = (numPhotos) => {
    const basePricePerPackage = prices['fivePhotosPrice']; // Price for a package of 5 photos
    const stepsOfPrice = numPhotos / 5; // Price per photo in a package

    const discountStart = 8;
    const discountIncrement = 1.1; // 25% discount per package after discountStart

    let totalPrice = 0;

    if (stepsOfPrice > 2) {
      totalPrice = 2 * basePricePerPackage + (((stepsOfPrice - 2) * basePricePerPackage) - (((discountStart * (discountIncrement * (stepsOfPrice - 2))) * (basePricePerPackage * (stepsOfPrice - 2))) / 100))
      setDiscount(Math.ceil((discountStart * (discountIncrement * (stepsOfPrice - 2)))))
    } else {
      totalPrice = stepsOfPrice * basePricePerPackage
      setDiscount(0)
    }

    return Math.ceil(totalPrice);
  };

  const [publicId, setPublicId] = useState("");
  // Replace with your own cloud name
  const [cloudName] = useState("dr1s0uxfc");
  // Replace with your own upload preset
  const [uploadPreset] = useState("rapmfruk");

  // Upload Widget Configuration
  // Remove the comments from the code below to add
  // additional functionality.
  // Note that these are only a few examples, to see
  // the full list of possible parameters that you
  // can add see:
  //   https://cloudinary.com/documentation/upload_widget_reference

  const [uwConfig] = useState({
    cloudName,
    uploadPreset,
    // cropping: true, //add a cropping step
    // showAdvancedOptions: true,  //add advanced options (public_id and tag)
    sources: ["local", "url", "camera"], // restrict the upload sources to URL and local files
    multiple: false,  //restrict upload to a single file
    // folder: "user_images", //upload files to the specified folder
    // tags: ["users", "profile"], //add the given tags to the uploaded files
    // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
    // clientAllowedFormats: ["images"], //restrict uploading to image files only
    // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
    // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
    // theme: "purple", //change to a purple theme
  });

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName
    }
  });

  const myImage = cld.image(publicId);

  return (
    <div className={classes.root + ' ce-cauti-in-codul-meu'}>
      <img src={og} style={{ display: 'none' }} />
      {beforeSubmit && <div className="beforeSubmit-wrapper">
        <div className="beforeSubmit">
          <CardTitles title="Now we gonna finish. Any last words?" subtitle="Use this field to tell more if something is missing." />
          <br />
          <TextareaAutosize
            value={lastWords}
            onChange={e => setLastWords(e.target.value)}
            name="lastWords"
            color="secondary"
            placeholder="Additional details if needed..."
            minRows={2}
            maxRows={3} />
          <small>Hope you checked all the data, now its time to do amazing stuff together!</small>
          <ReCAPTCHA
            sitekey="6LdG6tQpAAAAACsjJ05-8Jd1Slf6S5piotL1RQCA"
            onChange={setCaptcha}
            className="recaptcha"
          />

          {captcha && <small>An automated email will be sent to <strong>{email}</strong> with all the data you completed!</small>}

          <br />
          <br />

          {captcha && <Button type="button" variant="contained" color="success" onClick={submitEmail}>{loading ? 'Loading...' : 'Submit'}</Button>}
        </div>
        <div className="beforeSubmit-backdrop" onClick={() => setBeforeSubmit(false)}>
        </div>
      </div>
      }
      {carDetailsPopup && <div className="beforeSubmit-wrapper">
        <div className="beforeSubmit">
          <CardTitles title="I need to know your car" subtitle="A picture is worth a thousand words!" />
          <br />
          <div className="car-details-wrapper">
            <CloudinaryUploadWidget uwConfig={uwConfig} setPublicId={setPublicId} setCarPhoto={setCarPhoto} />
            {carPhoto && <img src={carPhoto} alt="Uploaded" style={{ maxHeight: '100px', width: 'auto', objectFit: 'contain' }} />}

            <TextField
              name="car"
              placeholder="Car Details"
              value={carDetails}
              onChange={e => setCarDetails(e.target.value)}
              required
              color="secondary"
            />

            <Button type="button" variant="contained" color="success" onClick={e => setCarDetailsPopup(false)}>Add Car Details</Button>
          </div>
        </div>
        <div className="beforeSubmit-backdrop" onClick={() => setCarDetailsPopup(false)}>
        </div>
      </div>
      }
      <header style={{ marginBottom: "20px" }}>
        <img src={logo} alt="Logo" className="logo" />
        <div className="desktop"><ComputerIcon /> Use a desktop device for a better experience!</div>
      </header>

      {availableSections.map((section, index) => (
        <div
          key={section.id}
          className={`horizontalCard ${index !== currentIndex && 'hidden'} with-drop-shadow`}
        >
          {
            currentIndex === (availableSections.length - 1) && scrollTop === 0 &&
            <div className="scroll-arrows">
              <div className="bottom-arrow"><ExpandMoreIcon /></div>
            </div>
          }
          <SectionDivider images={allDividers[section.id - 1]} video={availableSections[currentIndex].id === 3 || availableSections[currentIndex].id === 8 || availableSections[currentIndex].id === 9} />
          <div className="section-container">
            <Scrollbars
              style={{ width: '100%', height: '100%' }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              onScrollFrame={scrollEvent}
            >
              {section.condition && section.component}
            </Scrollbars>
          </div>


          <div className="background">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ))}
      {currentIndex > 0 && <div className={`arrowButton arrowButtonLeft`} onClick={handlePrev}>
        <ArrowBackIcon /> Prev
      </div>}
      {currentIndex > 0 && <div className={`summary ${currentIndex == (availableSections.length - 1) && 'last-index'}`}>
        {totalSum} Euro / {totalSumRon} RON
        <div className="subnotes">
          <form ref={form} onSubmit={sendEmail} className="summaryForm">
            <input type='text' name="name" value={name} />
            <input type='text' name="phone" value={phone} />
            <input type='text' name="email" value={email} />
            <input type='text' name="instagram" value={instagram} />
            <input type='text' name="carDetails" value={carDetails} />
            <input type='text' name="carPhoto" value={carPhoto} />
            <input type='text' name="numOfPhotos" value={numPhotos > 0 ? numPhotos : 'no'} />
            <input type='text' name="wantVideo" value={wantVideo ? 'yes' : 'no'} />
            <input type='text' name="videoType" value={wantVideo ? videoType : 'no video'} />
            <input type='text' name="wantCandid" value={wantCandid ? 'yes' : 'no'} />
            <input type='text' name="wantCase" value={wantCase ? 'yes' : 'no'} />
            <input type='text' name="phoneModel" value={wantCase ? phoneModel : 'case'} />
            <input type='text' name="wantPoster" value={wantPoster ? 'yes' : 'no'} />
            <input type='text' name="location" value={location ? location : 'location scouting'} />
            <input type='text' name="geoLocation" value={geoLocation ? geoLocation : 'no geo location'} />
            <input type='text' name="date" value={date ? date : 'date scouting'} />
            <input type='text' name="handoff" value={handoff ? 'Yes' : 'No'} />
            <input type='text' name="totalEuro" value={totalSum} />
            <input type='text' name="totalRon" value={totalSumRon} />
            <input type='text' name="lastWords" value={lastWords !== '' ? lastWords : 'none'} />
            {currentIndex === (availableSections.length - 1) && <Button type="submit" className="send-that" variant="contained" color="success">I want this!</Button>}


          </form>
        </div>
      </div>}
      {currentIndex < (availableSections.length - 1) && shouldNext && <div className={`arrowButton arrowButtonRight`} onClick={handleNext}>
        Next <ArrowForwardIcon />
      </div>}
    </div>
  );
}

export default App;
